.dropdown-menu {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.7rem;
  box-shadow: #ccc;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  scroll-behavior: smooth;
  padding: 0.4rem;
  position: relative;

  button {
    align-items: center;
    background-color: transparent;
    display: flex;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    gap: 0.25rem;
    border: none;
    text-align: left;
    width: 100%;
  
    &:hover, 
    &:hover.is-selected {
      background-color: #ccc;
    }

    &.is-selected {
      background-color: #ccc;
    }
  }
}