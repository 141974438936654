.tiptap {
  position: relative;
  height: 130px;
  min-height: 130px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  :first-child {
    margin-top: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.4;
  }
  
  .mention {
    background-color: #ccc;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: #000;
    padding: 0.1rem 0.3rem;
    background-color: #ccc;
  }
}

.tippy-content .dropdown-menu {
  overflow-y:auto;
  max-height: 200px;
  border-radius: 0;
}

.tippy-content .dropdown-menu:has(div:empty) {
  border:none;
}

