.rbc-event-label {
  display: none;
}

.rbc-event {
  border:none !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.rbc-agenda-time-cell {
  border: none !important;
}

.rbc-time-slot .rbc-label {
  font-size: 12px;
  font-weight: bold;
}

