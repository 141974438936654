body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.update-notification-popup svg {
  display: none;
}

.update-notification-popup button {
  border-radius: 4px;
  background-color: rgb(37, 69, 151);
}

.update-notification-popup button:hover {
  background-color: rgb(25, 48, 105);
}
  